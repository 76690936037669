<template>
	<div id="yieldskelly">
		<div class = "yieldskelly__info">
			<div class="yieldskelly__info__box box-shadow-1 ">
				<div class="pulse"></div>
			</div>

			<div class="yieldskelly__info__box box-shadow-1 ">
				<div class="pulse"></div>
			</div>

			<div class="yieldskelly__info__box box-shadow-1 ">
				<div class="pulse"></div>
			</div>

			<div class="yieldskelly__info__box box-shadow-1 ">
				<div class="pulse"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name : "YieldSkeleton"
}
</script>

<style>
#yieldskelly {
	display: flex;
}

.yieldskelly__info {
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  margin-right: 20px;
}

.yieldskelly__info__box {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin: 10px 10px 30px 0px;
  padding: 20px 20px;
  width: 70vw;
  height: 250px;
}

.yieldskelly__info__box div{
  background-color: rgb(211, 211, 211);
  width: 80%;
  height: 40px;
}


</style>